import constants from "./constants";

export default function formatPrice(priceFractional, currency) {
  if (typeof priceFractional === "undefined") return;
  const formatter = new Intl.NumberFormat(getLanguage(), {
    style: "currency",
    currency: currency || getCurrency(),
  });
  return formatter.format(priceFractional / 100);
}

export function formatDollars(price, currency) {
  if (typeof price === "undefined") return;
  const formatter = new Intl.NumberFormat(getLanguage(), {
    style: "currency",
    currency: currency || getCurrency(),
    minimumFractionDigits: 2,
  });
  return formatter.format(price);
}

function getLanguage() {
  const country = localStorage.getItem(constants.CONFIG.COUNTRY);
  switch (country?.toLowerCase()) {
    case "th":
      return "th-TH";
    default:
      return "en-SG";
  }
}

function getCurrency() {
  const currency = localStorage.getItem(constants.CONFIG.CURRENCY);
  return currency || "SGD";
}
