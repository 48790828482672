import { gql } from "@apollo/client";

export const GET_LOGISTICS_USAGE_QUERY = gql`
  query GetRestaurantLogisticsUsage($filter: LogisticSubscriptionUsageFilter) {
    getLogisticsSubscriptionUsage(filter: $filter) {
      totalCount
      page
      perPage
      data {
        id
        quantity
        data
        sourceType
        sourceId
        usageDatetime
      }
    }
  }
`;
