import React, { useState, useEffect, useContext } from "react";

import { useLazyQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import loadable from "@loadable/component";
import classNames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import ConfigContext from "@/components/Config/configContext";
import { GET_LOGISTICS_USAGE_QUERY } from "@/graphql/queries/getLogisticsUsage";
import { getHasEditPermissions } from "@/utils/accessControl";
import constants from "@/utils/constants";
import formatPercentage from "@/utils/formatPercentage";
import formatPrice from "@/utils/formatPrice";

const Button = loadable(() => import("@/common/Button"));
const Layout = loadable(() => import("@/common/Layout"));
const Paginator = loadable(() => import("@/common/Table/Paginator"));
const Select = loadable(() => import("@/common/Select"));
const ServingDateTypeRangePicker = loadable(() =>
  import("@/components/ServingDateTypeRangePicker"),
);
const Spin = loadable(() => import("@/common/Spin"));
const Table = loadable(() => import("@/common/Table"));
const Tooltip = loadable(() => import("@/common/Tooltip"));

dayjs.extend(utc);

export default function LogisticsReport() {
  const { t } = useTranslation();

  const { configQuery } = useContext(ConfigContext);

  const hasEditPermissions = getHasEditPermissions();

  const displayOnTimeDeliveries =
    hasEditPermissions ||
    configQuery?.config?.features?.includes(
      constants.FEATURES.DISPLAY_ON_TIME_DELIVERIES_ON_FULFILMENT_REPORT,
    );
  const [xOutletID, setXOutletID] = useState(
    localStorage.getItem(constants.X_OUTLET_ID),
  );

  const LOGISTIC_OPTIONS = {
    ALL: {
      label: t("common.all"),
      value: null,
    },
    LALAMOVE: {
      label: t("fulfilment.bookLogistics.deliveryProviders.lalamove"),
      value: constants.LOGISTICS_OPTIONS.LALAMOVE,
    },
    MILK_RUN: {
      label: t("fulfilment.bookLogistics.deliveryProviders.milk_run"),
      value: constants.LOGISTICS_OPTIONS.MILK_RUN,
    },
    GOGOX: {
      label: t("fulfilment.bookLogistics.deliveryProviders.gogox"),
      value: constants.LOGISTICS_OPTIONS.GOGOX,
    },
    PANDAGO: {
      label: t("fulfilment.bookLogistics.deliveryProviders.pandago"),
      value: constants.LOGISTICS_OPTIONS.PANDAGO,
    },
    ASL: {
      label: t("fulfilment.bookLogistics.deliveryProviders.asl_lalamove"),
      value: constants.LOGISTICS_OPTIONS.ASL,
    },
  };
  const [reports, setReports] = useState([]);
  const [logisticsUsages, setLogisticsUsages] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [reportOutletId, setReportOutletId] = useState(null);
  const [logisticsService, setLogisticsService] = useState(null);
  const [logisticsUsageStartDate, setLogisticsUsageStartDate] = useState(
    dayjs().utc().startOf("week"),
  );
  const [logisticsUsageEndDate, setLogisticsUsageEndDate] = useState(
    dayjs().utc().endOf("week"),
  );
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 200,
    defaultPageSize: 200,
    total: 0,
  });

  const [getRestaurantLogisticsUsages, { data = [], loading }] = useLazyQuery(
    GET_LOGISTICS_USAGE_QUERY,
    {
      context: { graph: "restaurants" },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setLogisticsUsages(data?.getLogisticsSubscriptionUsage?.data ?? []);
      },
    },
  );

  const allOutletIds = {
    value: null,
    label: t("salesReport.filters.allOutlets"),
  };

  const getExportableData = (logisticsUsages) => {
    return logisticsUsages?.map((logisticsUsage) => {
      const orders = logisticsUsage.data.orders.map((order) => order[0]);
      const addresses = logisticsUsage.data.orders.map((order) => order[1]);
      const columns = {
        bookedAt: logisticsUsage?.usageDatetime,
        bookedWith: constants.ATLAS_LOGISTICS_TYPE.includes(
          logisticsUsage?.data?.logistics_type,
        )
          ? t(`fulfilment.bookLogistics.deliveryProviders.asl_lalamove`)
          : t(
              `fulfilment.bookLogistics.deliveryProviders.${logisticsUsage?.data?.logistics_type}`,
            ),
        orders: logisticsUsage.data.orders,
        orderId: orders,
        orderValue: formatPrice(
          logisticsUsage.data?.orders_value > 0
            ? logisticsUsage.data?.orders_value
            : 0,
        ),
        outletName: logisticsUsage?.data?.outlet_label,
        addresses: addresses,
        distance: logisticsUsage?.data?.distance
          ? t(`fulfilmentReport.form.distance`, {
              distance: logisticsUsage?.data?.distance / 1000,
            })
          : t("fulfilmentReport.form.notApplicable"),
        logisticsCost: formatPrice(
          logisticsUsage?.quantity > 0 ? logisticsUsage?.quantity : 0,
        ),
        cancellations: formatPrice(
          logisticsUsage?.quantity > 0 ? 0 : logisticsUsage?.quantity,
        ),
        customerDeliveryFee: formatPrice(
          logisticsUsage?.data?.delivery_fee > 0
            ? logisticsUsage.data.delivery_fee
            : 0,
        ),
        costToFeeDifference: formatPrice(
          logisticsUsage?.quantity > 0
            ? logisticsUsage.quantity - (logisticsUsage.data?.delivery_fee || 0)
            : 0,
        ),
      };
      if (displayOnTimeDeliveries) {
        columns.pickupTime = logisticsUsage?.data?.pickup_at
          ? dayjs(logisticsUsage?.data?.pickup_at).format("hh:mm A")
          : t("fulfilmentReport.form.notApplicable");
        columns.dropOffTime = logisticsUsage?.data?.completed_at
          ? dayjs(logisticsUsage?.data?.completed_at).format("hh:mm A")
          : t("fulfilmentReport.form.notApplicable");
        setXOutletID;
      }
      columns.vehicle_type = logisticsUsage?.data?.vehicle_type
        ? t(
            `fulfilment.bookLogistics.serviceTypes.${logisticsUsage?.data?.vehicle_type}`,
          )
        : t("fulfilmentReport.form.notApplicable");
      return columns;
    });
  };

  const getVariables = () => {
    return {
      variables: {
        filter: {
          startDate: logisticsUsageStartDate,
          endDate: logisticsUsageEndDate,
          outletId: parseInt(reportOutletId),
          logisticsService: logisticsService,
        },
      },
    };
  };

  useEffect(() => {
    setXOutletID(localStorage.getItem(constants.X_OUTLET_ID));
    setReportOutletId(xOutletID == "all" ? reportOutletId : xOutletID);
  }, [[localStorage.getItem(constants.X_OUTLET_ID)]]);

  useEffect(() => {
    if (configQuery?.outlets) {
      setOutlets(
        configQuery.outlets.map((o) => ({ label: o.label, value: o.id })),
      );
    }
  }, [configQuery]);

  useEffect(() => {
    if (
      logisticsUsageStartDate ||
      logisticsUsageEndDate ||
      reportOutletId ||
      logisticsService
    ) {
      getRestaurantLogisticsUsages(getVariables());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logisticsUsageStartDate,
    logisticsUsageEndDate,
    reportOutletId,
    logisticsService,
  ]);

  useEffect(() => {
    if (data?.getLogisticsSubscriptionUsage) {
      const totalDeliveryCost =
        sumBy(data?.getLogisticsSubscriptionUsage?.data, "quantity") || 0;
      const totalDeliveryFee =
        sumBy(
          data?.getLogisticsSubscriptionUsage?.data,
          (data) => data.data.delivery_fee,
        ) || 0;
      const totalDeliverySales =
        data?.getLogisticsSubscriptionUsage?.data.reduce((acc, current) => {
          if (current.data.delivery_fee < 0) {
            acc -= current.data?.orders_value ?? 0;
          } else {
            acc += current.data?.orders_value ?? 0;
          }
          return acc;
        }, 0);
      const totalFeeToCostDifference = totalDeliveryCost - totalDeliveryFee;
      const costPercentage = totalFeeToCostDifference / totalDeliverySales;
      const totalTrips = data?.getLogisticsSubscriptionUsage?.data?.length;
      const totalCompletedTrips = filter(
        data?.getLogisticsSubscriptionUsage?.data,
        (usage) => usage.data?.completed_at,
      )?.length;
      const totalOnTimeTrips = filter(
        data?.getLogisticsSubscriptionUsage?.data,
        (usage) => usage.data?.on_time_delivery == true,
      )?.length;
      const totalOnTimeDeliveries = totalOnTimeTrips / totalCompletedTrips || 0;
      const totalCancellations = filter(
        data?.getLogisticsSubscriptionUsage?.data,
        (usage) => usage.quantity < 0,
      )?.length;

      const reports = [
        {
          name: t("fulfilmentReport.reportCards.totalTrips"),
          tooltip: t("fulfilmentReport.reportCards.tooltip.totalTrips"),
          amount: totalTrips - totalCancellations,
        },
        {
          name: t("fulfilmentReport.reportCards.totalCancellations"),
          tooltip: t("fulfilmentReport.reportCards.tooltip.totalCancellations"),
          amount: totalCancellations,
        },
        {
          name: t("fulfilmentReport.reportCards.totalDeliverySales"),
          tooltip: t("fulfilmentReport.reportCards.tooltip.totalDeliverySales"),
          amount: formatPrice(totalDeliverySales),
        },
        {
          name: t("fulfilmentReport.reportCards.totalLogisticsCost"),
          tooltip: t("fulfilmentReport.reportCards.tooltip.totalLogisticsCost"),
          amount: formatPrice(totalDeliveryCost),
        },
        {
          name: t("fulfilmentReport.reportCards.totalDeliveryFee"),
          tooltip: t("fulfilmentReport.reportCards.tooltip.totalDeliveryFee"),
          amount: formatPrice(totalDeliveryFee),
        },
        {
          name: t("fulfilmentReport.reportCards.totalDifference"),
          subtitle:
            !isNaN(costPercentage) && isFinite(costPercentage)
              ? formatPercentage(costPercentage)
              : null,
          tooltip: t("fulfilmentReport.reportCards.tooltip.totalDifference"),
          amount: formatPrice(totalFeeToCostDifference),
        },
      ];

      if (displayOnTimeDeliveries) {
        reports.push({
          name: t("fulfilmentReport.reportCards.onTimeDeliveries"),
          tooltip: t("fulfilmentReport.reportCards.tooltip.onTimeDeliveries"),
          amount: formatPercentage(totalOnTimeDeliveries),
        });
      }

      setReports(reports);
    }
  }, [data?.getLogisticsSubscriptionUsage]);

  const columns = [
    {
      title: t("fulfilmentReport.tableHeaders.bookedAt"),
      key: "usageDatetime",
      width: 110,
      className: "font-bold",
      fixed: "left",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-2 text-sm font-semibold">
              {dayjs(subscriptionUsage.usageDatetime).format(
                "DD MMM YYYY, hh:mm A",
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.bookedWith"),
      width: 90,
      key: "logisticsType",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {constants.ATLAS_LOGISTICS_TYPE.includes(
                subscriptionUsage?.data?.logistics_type,
              )
                ? t(`fulfilment.bookLogistics.deliveryProviders.asl_lalamove`)
                : t(
                    `fulfilment.bookLogistics.deliveryProviders.${subscriptionUsage?.data?.logistics_type}`,
                  )}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.orderId"),
      width: 50,
      key: "orderId",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {subscriptionUsage?.data?.orders?.map((order, index) => (
                <p key={index}>
                  {order[3] ? (
                    <a
                      href={`/orders?action=view&entryId=${order[3]}&entryType=order_detail`}
                    >
                      <span>#{order[0]}</span>
                    </a>
                  ) : (
                    <span>#{order[0]}</span>
                  )}
                </p>
              ))}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.orderValue"),
      width: 70,
      key: "orderValue",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {subscriptionUsage.data?.orders_value > 0
                ? formatPrice(subscriptionUsage.data?.orders_value)
                : "N/A"}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.dropOffAddress"),
      width: 160,
      key: "dropOffAddress",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {subscriptionUsage?.data?.orders?.map((order, index) => (
                <p key={index}>
                  <span>{order[1]}</span>
                  {subscriptionUsage?.data?.distance
                    ? t(`fulfilmentReport.form.distance`, {
                        distance: (
                          subscriptionUsage?.data?.distance / 1000
                        )?.toFixed(2),
                      })
                    : ""}
                </p>
              ))}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.logisticsCost"),
      width: 70,
      key: "logisticsCost",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {formatPrice(
                subscriptionUsage.quantity > 0 ? subscriptionUsage.quantity : 0,
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.cancellations"),
      key: "cancellations",
      width: 70,
      className: "font-semibold",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {formatPrice(
                subscriptionUsage.quantity > 0 ? 0 : subscriptionUsage.quantity,
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.customerDeliveryFee"),
      key: "customerDeliveryFee",
      width: 70,
      className: "font-semibold",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {formatPrice(
                subscriptionUsage.data?.delivery_fee > 0
                  ? subscriptionUsage.data?.delivery_fee
                  : 0,
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t("fulfilmentReport.tableHeaders.feeToCostDifference"),
      key: "feeToCostDifference",
      width: 110,
      className: "font-semibold",
      // eslint-disable-next-line react/display-name
      render: (_, subscriptionUsage) => {
        return (
          <>
            <div className="mb-1 text-sm font-semibold">
              {formatPrice(
                subscriptionUsage.quantity > 0 &&
                  subscriptionUsage.data?.delivery_fee >= 0
                  ? subscriptionUsage.quantity -
                      subscriptionUsage.data?.delivery_fee
                  : 0,
              )}
            </div>
          </>
        );
      },
    },
  ];

  if (displayOnTimeDeliveries) {
    columns.push(
      {
        title: t("fulfilmentReport.tableHeaders.pickupTime"),
        key: "pickupTime",
        width: 70,
        className: "font-semibold",
        // eslint-disable-next-line react/display-name
        render: (_, subscriptionUsage) => {
          return (
            <>
              <div className="mb-1 text-sm font-semibold">
                {subscriptionUsage?.data?.pickup_at
                  ? dayjs(subscriptionUsage?.data?.pickup_at).format("hh:mm A")
                  : t("fulfilmentReport.form.notApplicable")}
              </div>
            </>
          );
        },
      },
      {
        title: t("fulfilmentReport.tableHeaders.dropOffTime"),
        key: "dropOffTime",
        width: 70,
        className: "font-semibold",
        // eslint-disable-next-line react/display-name
        render: (_, subscriptionUsage) => {
          return (
            <>
              <div className="mb-1 text-sm font-semibold">
                {subscriptionUsage?.data?.completed_at
                  ? dayjs(subscriptionUsage?.data?.completed_at).format(
                      "hh:mm A",
                    )
                  : t("fulfilmentReport.form.notApplicable")}
              </div>
            </>
          );
        },
      },
    );
  }

  columns.push({
    title: t("fulfilmentReport.tableHeaders.vehicleType"),
    key: "vehicleType",
    width: 80,
    className: "font-semibold",
    // eslint-disable-next-line react/display-name
    render: (_, subscriptionUsage) => {
      return (
        <>
          <div className="mb-1 text-sm font-semibold">
            {subscriptionUsage?.data?.vehicle_type
              ? t(
                  `fulfilment.bookLogistics.serviceTypes.${subscriptionUsage?.data?.vehicle_type}`,
                )
              : t("fulfilmentReport.form.notApplicable")}
          </div>
        </>
      );
    },
  });

  function onPaginate(updatedPagination) {
    setPagination({
      ...pagination,
      current: updatedPagination.current,
      pageSize: updatedPagination.pageSize,
    });
  }

  return (
    <Layout pageTitle={t("fulfilmentReport.header")}>
      <div className="grid grid-cols-4 mb-4">
        <ServingDateTypeRangePicker
          orderStartDate={logisticsUsageStartDate}
          setOrderStartDate={setLogisticsUsageStartDate}
          orderEndDate={logisticsUsageEndDate}
          setOrderEndDate={setLogisticsUsageEndDate}
          className="mr-0"
          rangePickerClassName="w-full"
        />

        {xOutletID === "all" && (
          <div className="mr-2">
            <div className="block mb-2 text-xs font-medium text-gray-700 uppercase">
              {t("fulfilmentReport.filters.outlet")}
            </div>
            <Select
              options={[...outlets, allOutletIds]}
              value={parseInt(reportOutletId) || reportOutletId}
              placeholder={t("salesReport.filters.placeholders.outlet")}
              onChange={(outletId) => setReportOutletId(outletId)}
            />
          </div>
        )}

        {!loading && (
          <>
            <div>
              <div className="block mb-2 text-xs font-medium text-gray-700 uppercase">
                {t("fulfilmentReport.filters.logisticsService")}
              </div>
              <Select
                options={Object.values(LOGISTIC_OPTIONS)}
                value={logisticsService}
                placeholder={t("salesReport.filters.placeholders.outlet")}
                onChange={(logisticsService) =>
                  setLogisticsService(logisticsService)
                }
              />
            </div>

            {/* intentional empty div to keep the grid layout */}
            {xOutletID !== "all" && <div />}

            <div className="flex-grow mt-5 text-right">
              <Button type="primary">
                <CSVLink
                  className="text-white hover:text-white"
                  filename={`fulfilment_report_${logisticsUsageStartDate}_${logisticsUsageEndDate}.csv`}
                  data={getExportableData(logisticsUsages)}
                >
                  {t("fulfilmentReport.exportToCSV")}
                </CSVLink>
              </Button>
            </div>
          </>
        )}
      </div>

      <div className="mb-4 mx-full">
        <dl
          className={classNames(
            "grid",
            displayOnTimeDeliveries ? "grid-cols-7" : "grid-cols-6",
            "border border-gray-200 rounded-md shadow-md bg-gray-50",
          )}
        >
          {reports.map((card, index) => (
            <div
              key={card.name}
              className={classNames([
                "flex flex-col p-3 xl:p-6 text-center border-gray-200",
                index >= 4
                  ? "border-t xl:border-t-0"
                  : "border-b xl:border-b-0",
                index === 0
                  ? "border-l-0"
                  : index === 4
                  ? "border-l-0 xl:border-l"
                  : "border-l",
              ])}
            >
              <dt className="order-2 text-base font-medium leading-6 text-gray-500">
                {card.name}
                <Tooltip title={card.tooltip}>
                  <InformationCircleIcon className="inline-block w-5 h-5 ml-2 -mt-1 text-gray-400" />
                </Tooltip>
              </dt>
              <dd className="order-1 text-xl font-extrabold text-indigo-600">
                {card.amount}
                {card.subtitle && (
                  <>
                    <br />
                    <span className="text-base italic font-normal text-gray-500">
                      {card.subtitle}
                    </span>
                  </>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <Spin spinning={loading}>
        <Table
          dataSource={logisticsUsages}
          columns={columns}
          rowKey="id"
          scroll={{
            x: sumBy(columns, (col) => col.width),
            y: 800,
          }}
          pagination={{
            ...pagination,
            position: ["topRight", "bottomRight"],
            pageSizeOptions: [10, 20, 50, 100, 200],
            // eslint-disable-next-line react/display-name
            showTotal: (total, range) => (
              <Paginator total={total} range={range} />
            ),
          }}
          onChange={onPaginate}
        />
      </Spin>
    </Layout>
  );
}
